import React, { useContext } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/body/damaged-skin/aquaphor-healing-ointments-front.webp";
import backImage from "images/body/damaged-skin/aquaphor-healing-ointments-back.webp";
import frontZoomImage from "images/body/damaged-skin/aquaphor-healing-ointments-front-zoom.webp";
import backZoomImage from "images/body/damaged-skin/aquaphor-healing-ointments-back-zoom.webp";

import img2 from "images/body/damaged-skin/chart-aqupahor-ointment-breathable-barrier.webp";
import img3 from "images/body/damaged-skin/chart-aqupahor-ointment-re-epithelialization.webp";
import img4 from "images/body/damaged-skin/chart-aqupahor-ointment-laser-wound.webp";
import img5 from "images/body/damaged-skin/chart-aqupahor-ointment-epithelial.webp";
import img6 from "images/body/damaged-skin/chart-aqupahor-ointment-edema.webp";
import img7 from "images/body/damaged-skin/chart-aqupahor-ointment-erythema.webp";
import img8 from "images/body/damaged-skin/chart-aqupahor-ointment-treatment-areas.webp";
import img9 from "images/callouts/section-cout-journal-resource.webp";
import img10 from "images/callouts/cout-footer-aquaphor.webp";
import img11 from "images/callouts/cout-footer-resource.webp";

import "./aquaphorwoundcare.scss";

function AquaphorWoundcarePage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-body-aqua-woundcare page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Healing Oinment: Wound Care"
        pageDescription="Learn about Aquaphor Healing Ointment for wound care"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#003E7E"
              categoryName="Minor Wound Care"
              categoryFontColor="#ffffff"
              productName="Aquaphor Healing Ointment<sup>®</sup>"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold blue-text sub-title">
                  Creates an optimal healing environment—no antibiotics needed
                </p>
                <p>
                  <span className="font-semi-bold">
                    Aquaphor Healing Ointment for minor and post-procedure
                    wounds
                  </span>{" "}
                  is an optimal choice for skin barrier repair. Its breathable
                  barrier enables oxygen flow and regulates moisture to prevent
                  tissue maceration—aiding the natural healing process.
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>Purposeful formulation goes beyond petrolatum</span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Faster, superior wound healing vs antibiotic ointments
                      <sup>1</sup>
                    </span>
                  </li>
                  <li>
                    <span>
                      Promotes ceramide synthesis, which is necessary for
                      barrier recovery
                    </span>
                  </li>
                  <li>
                    <span>
                      <span className="font-semi-bold">
                        Absorbs 3x its weight
                      </span>{" "}
                      in natural secretions, so healing factors remain on the
                      wound
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold blue-text sub-title">
                  Active ingredient
                </p>
                <p className="font-semi-bold">
                  41% petrolatum (skin protectant)
                </p>
                <ul className="blue-bullets">
                  <li>
                    <span>Protects exposed skin surfaces</span>
                  </li>
                  <li>
                    <span>Locks in moisture</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold blue-text sub-title">
                  Key ingredients
                </p>
                <Row>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Panthenol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Softens and conditions skin</span>
                      </li>
                      <li>
                        <span>Binds water to support moisture</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Lanolin alcohol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Supports barrier repair</span>
                      </li>
                      <li>
                        <span>Defends against moisture loss</span>
                      </li>
                    </ul>
                  </Col>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Glycerin</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Binds water</span>
                      </li>
                      <li>
                        <span>Replenishes moisture reservoir</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Bisabolol</p>
                    <ul className="blue-bullets">
                      <li>
                        <span>Soothes dry, irritated skin</span>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold blue-text sub-title">
                    Formulated for minor wound care
                  </p>
                  <ul className="checkmarks blue">
                    <li>
                      <span>hypoallergenic</span>
                    </li>
                    <li>
                      <span>non-comedogenic</span>
                    </li>
                    <li>
                      <span>preservative-free</span>
                    </li>
                    <li>
                      <span>Fragrance-free</span>
                    </li>
                  </ul>
                </div>
                <p className="references last">
                  <span className="font-bold">Reference: 1.</span> Data on file.
                  Beiersdorf Inc.
                </p>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="BREATHABLE BARRIER"
              productThemeColor="eucerin-blue"
              id="breathablebarrier"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Wound healing with breathable barrier"
                  />
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content left-align">
                    <p className="small-p">
                      Studies demonstrate that <nobr>semi-occlusive</nobr>{" "}
                      dressings improve wound healing vs occlusive dressings
                    </p>
                    <p className="font-18 font-semi-bold font-slate-grey">
                      How a semi-occlusive formulation helps:
                    </p>
                    <ul className="blue-bullets">
                      <li>
                        <span>
                          <span className="font-semi-bold">
                            Enables transepidermal water loss
                          </span>{" "}
                          to signal the body to{" "}
                          <span className="font-semi-bold">begin repair</span>{" "}
                          of the disrupted barrier
                        </span>
                      </li>
                      <li>
                        <span>
                          <span className="font-semi-bold">
                            Promotes ceramide synthesis,
                          </span>{" "}
                          which is necessary for barrier recovery
                        </span>
                      </li>
                      <li>
                        <span>
                          <span className="font-semi-bold">
                            Allows oxygen to enter;
                          </span>{" "}
                          vital for cells and tissues
                        </span>
                      </li>
                      <li>
                        <span>
                          <span className="font-semi-bold">
                            Regulates moisture
                          </span>{" "}
                          to prevent tissue maceration
                        </span>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Wound Healing"
              productThemeColor="eucerin-blue"
              id="woundhealing"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Showing difference of wound healing with semi-oculsive ointment"
                  />
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content left-align">
                    <p className="small-p">
                      Choose Aquaphor Healing Ointment for enhanced wound
                      healing
                    </p>
                    <ul className="blue-bullets">
                      <li>
                        <span>
                          <span className="font-semi-bold">Restricts</span> scab
                          formation
                        </span>
                      </li>
                      <li>
                        <span>
                          <span className="font-semi-bold">Accelerates</span>{" "}
                          re-epithelialization
                        </span>
                      </li>
                      <li>
                        <span>
                          <span className="font-semi-bold">Hastens</span> minor
                          wound healing
                        </span>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="WOUND IMPROVEMENT"
              productThemeColor="eucerin-blue"
              id="woundimprovement"
            >
              <Row
                align="center"
                justify="center"
                direction="row"
                id="acc-wound-improvement-row"
              >
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Faster barrier repair and superior would healing vs antibiotic ointments chart"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> A
                    double-blind study comparing the wound-healing properties of
                    topical wound care ointments. A laser wound was treated{" "}
                    <nobr>3 times</nobr> daily for 18 days with Aquaphor Healing
                    Ointment, Neosporin, or Neosporin Polysporin, or left
                    untreated. Erythema, edema, epithelial confluence, and
                    general wound appearance were assessed for safety and
                    efficacy (N=20).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Trookman
                    NS, Rizer RL, Weber T. Treatment of minor wounds from
                    dermatologic procedures: a comparison of three topical wound
                    care ointments using a laser wound model.{" "}
                    <span className="font-italic">J Am Acad Dermatol</span>.
                    2011;64(3 suppl):S8-S15.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Statistically significant
                    <br className="show-desktop" /> improvement in
                    <br className="show-desktop" /> general wound
                    <br className="show-desktop" /> appearance (Days 7-18)
                    <br className="show-desktop" />
                    —without use of
                    <br className="show-desktop" /> antibiotics
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Epithelial Confluence"
              productThemeColor="eucerin-blue"
              id="epithelialconfluence"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img5}
                    className="accordion-chart"
                    alt="Graph showing improvements in epithelial confluence"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> A
                    double-blind study comparing the wound-healing properties of
                    topical wound care ointments. A laser wound was treated{" "}
                    <nobr>3 times</nobr> daily for 18 days with Aquaphor Healing
                    Ointment, Neosporin, or Neosporin Polysporin, or left
                    untreated. Erythema, edema, epithelial confluence, and
                    general wound appearance were assessed for safety and
                    efficacy (N=20).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Trookman
                    NS, Rizer RL, Weber T. Treatment of minor wounds from
                    dermatologic procedures: a comparison of three topical wound
                    care ointments using a laser wound model.{" "}
                    <span className="font-italic">J Am Acad Dermatol</span>.
                    2011;64(3 suppl):S8-S15.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Faster, superior wound
                    <br className="show-desktop" /> healing vs antibiotic
                    <br className="show-desktop" /> ointments<sup>2</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Edema"
              productThemeColor="eucerin-blue"
              id="edema"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img6}
                    className="accordion-chart"
                    alt="Graph showing improvements in edema"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> A
                    double-blind study comparing the wound-healing properties of
                    topical wound care ointments. A laser wound was treated{" "}
                    <nobr>3 times</nobr> daily for 18 days with Aquaphor Healing
                    Ointment, Neosporin, or Neosporin Polysporin, or left
                    untreated. Erythema, edema, epithelial confluence, and
                    general wound appearance were assessed for safety and
                    efficacy (N=20).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Trookman
                    NS, Rizer RL, Weber T. Treatment of minor wounds from
                    dermatologic procedures: a comparison of three topical wound
                    care ointments using a laser wound model.{" "}
                    <span className="font-italic">J Am Acad Dermatol</span>.
                    2011;64(3 suppl):S8-S15.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Faster, superior wound <br className="show-desktop" />{" "}
                    healing vs antibiotic
                    <br className="show-desktop" /> ointments<sup>2</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="Erythema"
              productThemeColor="eucerin-blue"
              id="erythema"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img7}
                    className="accordion-chart"
                    alt="Graph showing improvements in erythema"
                  />
                  <p className="footnotes">
                    <span className="font-bold">Study design:</span> A
                    double-blind study comparing the wound-healing properties of
                    topical wound care ointments. A laser wound was treated{" "}
                    <nobr>3 times</nobr> daily for 18 days with Aquaphor Healing
                    Ointment, Neosporin, or Neosporin Polysporin, or left
                    untreated. Erythema, edema, epithelial confluence, and
                    general wound appearance were assessed for safety and
                    efficacy (N=20).
                  </p>
                  <p className="references">
                    <span className="font-bold">Reference: 2.</span> Trookman
                    NS, Rizer RL, Weber T. Treatment of minor wounds from
                    dermatologic procedures: a comparison of three topical wound
                    care ointments using a laser wound model.{" "}
                    <span className="font-italic">J Am Acad Dermatol</span>.
                    2011;64(3 suppl):S8-S15.
                  </p>
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content">
                    Faster, superior wound <br className="show-desktop" />{" "}
                    healing vs antibiotic
                    <br className="show-desktop" /> ointments<sup>2</sup>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="TREATMENT AREAS"
              productThemeColor="eucerin-blue"
              id="treatmentareas"
            >
              <Row>
                <Col sm={12} md={8}>
                  <img
                    src={img8}
                    className="accordion-chart"
                    alt="Icons indicating treatment areas"
                  />
                </Col>
              </Row>
            </AccordionContent>
          </section>
          <section className="callouts-container gradient-bg section-support-resource">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                  <TypeC
                    buttonText="Read the article"
                    buttonUrl="https://www.jaad.org/article/S0190-9622(10)02111-0/fulltext"
                    className="center-v"
                    isExternalLink={true}
                    targetOpen="_blank"
                  >
                    <p className="reg-text-big">
                      Support your recommendation
                      <br className="show-desktop" /> with this resource
                    </p>
                    <p className="reg-text">
                      The article that changed many minds about using topical
                      antibiotic ointments
                      <br className="show-desktop" /> for minor wounds.
                    </p>
                  </TypeC>
                </Col>
                <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                  <img
                    src={img9}
                    alt="Thoughts about using topical antibiotic ointments for minor wounds"
                    className="cout-product-img"
                  />
                </Col>
              </Row>
            </div>
          </section>
          <section className="callouts-container gradient-bg blue-grad">
            <div className="inner-centered-container">
              <Row>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img10}
                    imgAlt="Aquaphor Healing Ointment®"
                    caption="Take the Aquaphor<sup>®</sup> Approach to<br class='show-desktop' /> enhance healing of dry, cracked skin"
                    buttonText="Aquaphor Healing Ointment<sup>®</sup>"
                    buttonUrl="/body/damagedskin/aquaphordrycrackedskin"
                    imgStyles={{ height: 165 }}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <TypeA
                    imgRef={img11}
                    imgAlt="Resources to help skin"
                    caption="Help skin heal with a<br class='show-desktop' /> <nobr>semi-occlusive</nobr> barrier"
                    buttonText="Download resource"
                    buttonUrl={getUrlFromSlug(
                      "the-optimal-healing-environment-of-aquaphor",
                      state?.sanityData?.allResources
                    )}
                    imgStyles={{ height: 165 }}
                    isExternalLink={true}
                    targetOpen="_blank"
                  />
                </Col>
              </Row>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default AquaphorWoundcarePage;
